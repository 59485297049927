import { Trans } from "next-i18next"

export const desktopContent = t => {
    return [
        {
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/Cleanser%20-%20DTC%20HPB-02.jpg',
            image: 'https://storage.googleapis.com/base-lp-assets/output.lin.jpg',
            titleImageAlt: 'Centamide Gentle Hydrating Cleanser',
            title: <Trans i18nKey={'landing:hero.6.title'}><br/></Trans>,
            description: t('landing:hero.6.description'),
            colorCode: '#E5E5E5',
            productSlug: '/cleanser/centamide-gentle-hydrating-cleanser',
            colorMode: 'dark',
        },
        {
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/AURA%20WONDERS%20-%20DTC-01.png',
            image: 'https://storage.googleapis.com/base-lp-assets/AURA%20WONDERS%20-%20DTC%20HOMEPAGE%20(1).png',
            titleImage: 'https://storage.googleapis.com/base-lp-assets/AURA%20WONDERS%20-%20DTC%20logo%20.png',
            titleImageMobile: 'https://storage.googleapis.com/base-lp-assets/aura%20wonders%20logo%20(1).png',
            titleImageAlt: 'aura wonders eyeshadow',
            title: <Trans i18nKey={'landing:hero.5.title'}><br/></Trans>,
            description: t('landing:hero.5.description'),
            colorCode: '#E5E5E5',
            productSlug: '/eyeshadow/aura-wonders-eyeshadow-palette',
            colorMode: 'dark',
        },
        {
            imageMobile: 'https://static.base.co.id/multiuse-banner-mobile.png',
            image: 'https://static.base.co.id/multiuse-banner.png',
            titleImage: 'https://static.base.co.id/seamless-days-icon.png',
            titleImageMobile: 'https://static.base.co.id/seamless-days-icon.png',
            titleImageAlt: 'multiuse seamless days',
            title: <Trans i18nKey={'landing:hero.0.title'}><br/></Trans>,
            description: t('landing:hero.0.description'),
            colorCode: '#E5E5E5',
            productSlug: '/multi-use-cream/base-seamless-days-3-in-1-cheek-lip-eye-color',
            colorMode: 'dark',
        },
        {
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/M%20BANNER%201.png',
            image: 'https://storage.googleapis.com/base-lp-assets/Banner%201%20Desktop.png',
            titleImage: 'https://storage.googleapis.com/base-lp-assets/gloss-n-go-title.png',
            titleImageMobile: 'https://storage.googleapis.com/base-lp-assets/gloss-n-go-title.png',
            titleImageAlt: 'liptint',
            title: <Trans i18nKey={'landing:hero.1.title'}><br/></Trans>,
            description: t('landing:hero.1.description'),
            colorCode: '#E5E5E5',
            productSlug: '/lip-tint/base-gloss-and-go-lip-tint',
            colorMode: 'dark',
        },
        {
            image: 'https://storage.googleapis.com/base-lp-assets/BANNER%205%20Desktop.png',
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/M%20BANNER%205.png',
            titleImage: 'https://storage.googleapis.com/base-lp-assets/Bodycare%20-%20DTC%205in1.png',
            titleImageMobile: 'https://storage.googleapis.com/base-lp-assets/Bodycare%20-%20DTC%205in1.png',
            titleImageAlt: 'body care',
            title: <Trans i18nKey={'landing:hero.2.title'}><br/></Trans>,
            description: t('landing:hero.2.description'),
            colorCode: '#FFEDE0',
            productSlug: '/bundle/body-care-duo',
            colorMode: 'dark',
        },
        {
            image: 'https://storage.googleapis.com/base-lp-assets/Banner%202%20Desktop.png',
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/M%20BANNER%202.png',
            title: t('landing:hero.3.title'),
            description: t('landing:hero.3.description'),
            colorCode: '#FFEDE0',
            productSlug: '/lip-mask/base-overnight-moisture-lip-mask',
            colorMode: 'dark',
        },
        {
            image: 'https://storage.googleapis.com/base-lp-assets/Banner%203%20Desktop.png',
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/M%20BANNER%203%20Desktop.png',
            title: t('landing:hero.4.title'),
            description: t('landing:hero.4.description'),
            colorCode: '#FFE5DB',
            productSlug: '/serum/rich-moisture-and-youthful-firming-serum',
            colorMode: 'dark',
        },
    ]
}

